import { DateTime } from 'luxon';

const storeOptions = {
    persist: {
        paths: undefined,
        storage: persistedState.localStorage,
    },
};

export default (projectId: string | undefined) => {
    const uuid = useUUID();

    return defineStore(
        `project-${projectId || 'temporary'}-dashboards`,
        () => {
            const dashboards: Ref<ZettascaleDashboard[]> = ref([
                {
                    id: 'default',
                    label: 'Default',
                    entries: [],
                    pinnedData: [],
                    createdAt: DateTime.now().toISO(),
                } as ZettascaleDashboard,
            ]);

            function saveDashboard(dashboard: ZettascaleDashboard) {
                dashboards.value = [
                    ...dashboards.value.filter((d) => d.id !== dashboard.id),
                    dashboard,
                ];
            }

            function createDashboard(label: string) {
                const id = uuid();

                dashboards.value = [
                    ...dashboards.value,
                    {
                        id,
                        label,
                        entries: [],
                        pinnedData: [],
                        createdAt: DateTime.now().toISO(),
                    },
                ];

                return id;
            }

            function removeDashboard(dashboard: ZettascaleDashboard) {
                dashboards.value = dashboards.value.filter(
                    (d) => d.id !== dashboard.id
                );
            }

            function addToDashboard(
                dashboardId: string,
                keyExpr: string,
                live?: boolean
            ) {
                const target = dashboards.value.find(
                    (d) => d.id === dashboardId
                );

                if (
                    target &&
                    !target.entries.find((e) => e.keyExpr === keyExpr)
                ) {
                    saveDashboard({
                        ...target,
                        entries: [
                            ...target.entries,
                            {
                                creationDate: DateTime.now().toISO(),
                                id: uuid(),
                                keyExpr,
                                kind: live ? 'pget' : 'get',
                            },
                        ],
                    });
                }
            }

            return {
                dashboards,
                saveDashboard,
                createDashboard,
                removeDashboard,
                addToDashboard,
            };
        },
        storeOptions
    )();
};
